import { Router } from "@angular/router";
import { BuyerService } from "src/app/services/buyer.service";
import { SupplierService } from "src/app/services/supplier.service";
import { AuthService } from "./../../../services/auth/auth.service";
import { OnBoardingService } from "./../../../modules/buyer/services/onboarding.service";
import { CommonService } from "../../../services/common.service";
import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
  AfterViewChecked,
  EventEmitter,
  Renderer2,
  NgZone,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { DomHandler } from "../dialog/domHandler";
import { trigger, state, style } from "@angular/animations";
import {
  Confirmation,
  DialogType,
  ButtonCommands,
  APReivewService,
  ButtonItem,
  DialogService,
  PPReviewService,
} from "src/app/services/dialog.service";
import {
  SupplierMappingDTO,
  createSupplierMapping,
  createAPReviewModel,
  createAPApproveItem,
  SupplierPendingStatus,
  SapResultModel,
  SapResultItem,
  StatusType,
  AP_ReviewStatus,
  SupplierStatus,
  RejectComments,
  LoadingUrlMapping,
  ThirdPartySapResultItem,
  GstRegistrationSapItem,
  AlternativePayInformation,
  GSPartnerInformation,
  WTaxIDModel,SelectedReason
} from "src/app/interfaces/mapping";
import { OnBoardMessage } from "src/app/interfaces/buyer";
import * as moment from "moment";
import { cloneDeep, trim } from "src/app/utils";
import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { AllTaskKey, Task, ProcessNames } from "src/app/interfaces/workflow";
import { WorkflowService } from "src/app/services/workflow.service";
import { SupplierModule } from "src/app/modules/supplier/supplier.module";
import { SupplierModel } from "src/app/interfaces/supplierModel";
import { DictionaryService } from "src/app/services/dictionary.service";
import { LaunchDarklyService } from "src/app/services/launch-darkly.service";
import {
  AdditionalGSTRegistrationInformation,
  ThirdPartyPaymentInformation,
} from "src/app/interfaces/supplierProfile";
import { isNullOrUndefined } from "util";
import _ from "lodash";

// enum CheckEmailStatus {
//   EmailExist = 'EmailExist',
//   DomainExist = 'DomainExist',
//   NoProblem = 'NoProblem'
// }
// enum clickType {
//   replace = 'replace',
//   invitation = 'invitation'
// }

@Component({
  selector: "app-dialog-form",
  templateUrl: "./dialog-form.component.html",
  styleUrls: ["./dialog-form.component.sass"],
  animations: [
    trigger("dialogState", [
      state(
        "hidden",
        style({
          opacity: 0,
        })
      ),
      state(
        "visible",
        style({
          opacity: 1,
        })
      ),
    ]),
  ],
})
export class DialogFormComponent
  implements AfterViewInit, AfterViewChecked, OnDestroy
{
  confirmation: Confirmation;
  subscription: Subscription;
  ppSubscription: Subscription;
  @Input() header: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() buttons: ButtonItem[];
  @Input() width: any;
  @Input() height: any;
  @Input() closeOnEscape = true;
  @Input() rtl: boolean;
  @Input() closable = true;
  @Input() responsive = true;
  @Input() appendTo: any;
  @Input() key: string;

  @ViewChild("dialogForm") dialogForm: ElementRef;
  DialogType = DialogType;
  RejectComments = RejectComments;
  sapResult: SapResultModel;
  thirdPartyList: ThirdPartyPaymentInformation[] = [];
  thirdPartySAPList: ThirdPartySapResultItem[] = [];
  alternativePayList: AlternativePayInformation[] = [];
  _visible: boolean;
  documentEscapeListener: any;
  documentResponsiveListener: any;
  mask: any;
  gstRegistrationList: AdditionalGSTRegistrationInformation[] = [];
  gstRegistrationSapList: Array<GstRegistrationSapItem>;
  gsPartnerList: GSPartnerInformation[] = [];
  creationAPValidated = true;
  migrationAPValidated = true;
  existedAPValidated = true;
  contentContainer: any;
  positionInitialized: boolean;
  executePostShowActions: boolean;
  headerIcon: string;
  headerIconClass: string;
  title = "title";
  dialogType: DialogType;
  isValidated: boolean;
  companyCodeDict: any;
  rand =Math.round((Math.random()*10000)).toString();
  // sapIdValue: Array<string> = []
  // apApproveList: Array<any> = []
  private alert: any;
  private dictTaken: any;
  // CheckEmailStatus = CheckEmailStatus
  // isEmailPatterned: boolean
  dropDownDict: any;
  sapIdValidate: boolean;
  sapIdValueValidate: boolean;
  isEmptyChoice = false;
  withholdingTax: any = [];
  hasCountryUSIN = false;
  supplierGeographical: string;

  taskInst: Task;

  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
  sapIdFlag = true;
  isNewVersion: boolean = true;
  selectReasons: SelectedReason[];
  selectedReason:SelectedReason;
  selectedReasonDetails = '';
  isPPApproveSubmitPopupVisible: boolean = false;
  rejectionOptions: SelectedReason[];
  rejectionOption:SelectedReason;
  constructor(
    public el: ElementRef,
    public domHandler: DomHandler,
    public zone: NgZone,
    public renderer: Renderer2,
    private router: Router,
    private dictionaryService: DictionaryService,
    private apReviewService: APReivewService,
    private ppReviewService:PPReviewService,
    private onboardingService: OnBoardingService,
    private auth: AuthService,
    private buyerService: BuyerService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private loadingService: LoadingService,
    private workflowService: WorkflowService,
    private commonService: CommonService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.ppSubscription =  ppReviewService.ppReview$.subscribe(
      async (confirmation) => {
        if (confirmation.key === this.key) {
          this.dialogType = confirmation.dialogType;
          this.title = confirmation.options.title;
          if (
            this.dialogType === DialogType.ppApprove &&
            this.selectReasons == undefined
          ) {
            this.loadingService.openLoading('');
            let selectReasonsResponse = await this.buyerService.getApproveRejectReasons('approve');
            this.selectReasons = selectReasonsResponse?.data;
            this.loadingService.closeLoading();
          }
          else if (
            this.dialogType === DialogType.ppReject &&
            this.rejectionOptions == undefined
          ) {
              this.loadingService.openLoading('');
              let selectRejectionResponse = await this.buyerService.getApproveRejectReasons('reject');
              this.rejectionOptions = selectRejectionResponse?.data;
              let selectReasonsResponse = await this.buyerService.getApproveRejectReasons('reasonOption');
              this.selectReasons = selectReasonsResponse?.data;
              this.loadingService.closeLoading();
          }
          this.visible = true;
        }
      }
    );
    this.subscription = apReviewService.requireReview$.subscribe(
      async (confirmation) => {
        this.dropDownDict = this.dictionaryService.getDictionaryFromCache();
        if (confirmation.key === this.key) {
          this.isValidated = false;
          this.dialogType = confirmation.dialogType;
          this.taskInst = confirmation.task;
          this.title = confirmation.options.title;
          this.sapResult = createAPReviewModel();
          const requestorDict: any = this.dropDownDict.filter(
            (dict) => dict.Code === "requestor-details"
          )[0].Items;
          const supplierstep2Dict: any = this.dropDownDict.filter(
            (dict) => dict.Code === "supplier-step2"
          )[0].Items;
          this.companyCodeDict = requestorDict.filter(
            (dict) => dict.Code === "CompanyCode"
          )[0].Items;
          // workflow comments: sst review and variable is approve
          if (this.dialogType === DialogType.sstApprove) {
            const apResultLength =
              this.onboardingService.supplierModel.Mapping.assessmentRate
                .sapResult.length;
            this.supplierGeographical =
              this.onboardingService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierGeographical;
            let sapResultList = [];
            this.thirdPartySAPList = [];
            this.alternativePayList = [];
            this.gstRegistrationSapList = [];
            this.gsPartnerList = [];
            if (apResultLength > 0) {
              sapResultList = cloneDeep(
                this.onboardingService.supplierModel.Mapping.assessmentRate
                  .sapResult[apResultLength - 1].sapResultList
              );
              // Clear the value of check box check status and SAP ID
              sapResultList.forEach((clearItem) => {
                clearItem.twoSeries = false;
                clearItem.threeSeries = false;
                // clearItem.sapId = ''
              });
            }
            await this.onboardingService.loadSupplierProfile();
            this.gstRegistrationList =
              this.onboardingService.supplierModel.SupplierProfile.SupplierOrganization.AdditionalGSTRegistrationList;
            if (
              this.gstRegistrationList &&
              this.gstRegistrationList.length > 0
            ) {
              const onboarding = this.onboardingService;
              const indiaStateDict: any = supplierstep2Dict.filter(
                (dict) => dict.Code === "IndiaState"
              )[0].Items;
              this.gstRegistrationSapList = this.gstRegistrationList.map(
                function (i: any) {
                  const stateValue = indiaStateDict.find(
                    (item) => item.Code === i.AdditionalState
                  );
                  const temp: GstRegistrationSapItem = {
                    gstCountryId: `${i.additionalGSTRegistrationInformationId}`,
                    gstStateKey: stateValue.Code,
                    gstState: stateValue.Text,
                    sapId: "",
                  };
                  const gstSapItem =
                    onboarding.supplierModel.Mapping.gstSapItem;
                  if (gstSapItem && gstSapItem.length > 0) {
                    const singlegstSapItem: any = gstSapItem.find(
                      (a) =>
                        a.gstCountryId ===
                        `${i.additionalGSTRegistrationInformationId}`
                    );
                    temp.sapId = isNullOrUndefined(singlegstSapItem)
                      ? ""
                      : singlegstSapItem.sapId;
                  }
                  if (temp.sapId === "") {
                    this.sapIdFlag = false;
                  }
                  return temp;
                }.bind(this)
              );
              if (
                !!onboarding.supplierModel.Mapping.assessmentRate &&
                !!onboarding.supplierModel.Mapping.assessmentRate.sapResult
              ) {
                onboarding.supplierModel.Mapping.assessmentRate.sapResult.map(
                  (sap) => {
                    if (
                      !!sap.gstPartnerResult &&
                      sap.gstPartnerResult.length > 0
                    ) {
                      this.gsPartnerList = [];
                      sap.gstPartnerResult.map((gst) => {
                        const copyGST = cloneDeep(gst);
                        this.gsPartnerList.push({
                          gsPartnerName: copyGST.gsPartnerName,
                          gsPartnerList:
                            this.sapIdFlag &&
                            this.gstRegistrationSapList.length ===
                              gst.gsPartnerList.length
                              ? copyGST.gsPartnerList
                              : this.gstRegistrationSapList,
                        });
                      });
                    }
                  }
                );
              }
              if (this.gsPartnerList.length === 0) {
                this.gsPartnerList.push({
                  gsPartnerName: "",
                  gsPartnerList: this.gstRegistrationSapList,
                });
              }
              this.sapResult.gstPartnerResult = this.gsPartnerList;
            }
            this.thirdPartyList =
              this.onboardingService.supplierModel.SupplierProfile.SupplierFinancial.ThirdPartyPaymentInformationList;
            if (
              !!this.thirdPartyList &&
              this.thirdPartyList.length > 0 &&
              this.thirdPartySAPList.length < this.thirdPartyList.length
            ) {
              this.thirdPartyList.forEach(
                (item: ThirdPartyPaymentInformation) => {
                  const thirdPartyItem: ThirdPartySapResultItem = {
                    thirdPartyId: item.thirdPartyPaymentId,
                    thirdPartyName: item.ThirdPartyCompanyName,
                    sapId: "",
                  };
                  if (
                    this.onboardingService.supplierModel.Mapping.assessmentRate
                      .sapResult.length > 0
                  ) {
                    const thirdPartyResult: ThirdPartySapResultItem[] =
                      this.onboardingService.supplierModel.Mapping
                        .assessmentRate.sapResult[apResultLength - 1]
                        .thirdPartySAPResult;
                    if (!!thirdPartyResult && thirdPartyResult.length > 0) {
                      const tempSAP: ThirdPartySapResultItem =
                        thirdPartyResult.filter(
                          (a: ThirdPartySapResultItem) =>
                            a.thirdPartyId === item.thirdPartyPaymentId
                        )[0];
                      thirdPartyItem.sapId = tempSAP ? tempSAP.sapId : "";
                    }
                  }
                  this.thirdPartySAPList.push(thirdPartyItem);
                }
              );
              if (
                this.onboardingService.supplierModel.Mapping.assessmentRate
                  .sapResult.length > 0
              ) {
                this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.forEach(
                  (sap) => {
                    if (
                      !!sap.alterNativePayeeResult &&
                      sap.alterNativePayeeResult.length > 0
                    ) {
                      this.alternativePayList = [];
                      sap.alterNativePayeeResult.forEach((alt) => {
                        const copyGST = cloneDeep(alt);
                        this.alternativePayList.push({
                          alternativePayName: copyGST.alternativePayName,
                          thirdPartyList: this.thirdPartySAPList,
                        });
                      });
                    }
                  }
                );
              }
              this.sapResult.thirdPartySAPResult = this.thirdPartySAPList;
              if (this.alternativePayList.length === 0) {
                this.alternativePayList.push({
                  alternativePayName: "",
                  thirdPartyList: this.thirdPartySAPList,
                });
              }
              this.sapResult.alterNativePayeeResult = this.alternativePayList;
            }
            const companyCodes =
              this.onboardingService.supplierModel.Mapping.searchTerm
                .companyCode;
            // init WithholdingTax
            const countryWithholdingTax = this.dropDownDict.filter(
              (dict) => dict.Code === "CountryWithholdingTax"
            )[0].Items;
            countryWithholdingTax.map((item) => {
              this.withholdingTax.push(cloneDeep(item));
            });
            if (apResultLength > 0) {
              this.setWTaxValue(
                sapResultList,
                this.onboardingService.supplierModel.Mapping.assessmentRate
                  .sapResult
              );
            }
            // init sapResult
            this.initSapResult(sapResultList, companyCodes);
          } else {
            const apResultLength =
              this.onboardingService.supplierModel.Mapping.assessmentRate
                .sapResult.length;
            // Get latest SAP Result List
            if (apResultLength > 0) {
              this.sapResult.sapResultList =
                this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult[
                  apResultLength - 1
                ].sapResultList;
              this.sapResult.thirdPartySAPResult =
                this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult[
                  apResultLength - 1
                ].thirdPartySAPResult;
            }
          }
          // this.dialogForm.nativeElement.style.height = '32rem'

          if (
            this.dialogType === DialogType.sstApprove &&
            this.dictTaken === undefined
          ) {
            this.dictTaken =
              this.dictionaryService.getDictionaryFromCache("Action Taken");
          }
          this.visible = true;
        }
      }
    );
    this.launchDarklyService.ldChange.subscribe(any => {
      console.log("Trigger refresh data")
      this.refreshToggle()
    })
  }
  refreshToggle():void{
    this.isNewVersion=this.launchDarklyService.getToggle(LaunchDarklyService.LD_ISNEWVERSION)==="true";
  }
  /**
   * Generate uuid for WTaxID
   */
  generateUUID() {
    let timeStamp = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (char) {
        const number = (timeStamp + Math.random() * 16) % 16 | 0;
        timeStamp = Math.floor(timeStamp / 16);
        return (char == "x" ? number : (number & 3) | 8).toString(16);
      }
    );
    return uuid;
  }

  addAPResultItem(companyCodeItem, isFirstMigrationApprove = false) {
    const searchTermObj =
      this.onboardingService.supplierModel.Mapping.searchTerm;
    let countUSIN = 0;
    this.companyCodeDict.forEach((companyCodeList) => {
      companyCodeList.Items.forEach((item: any, index: number) => {
        if (item.Code === companyCodeItem) {
          const APResultItem: SapResultItem = createAPApproveItem();
          APResultItem.WTaxUUID = this.generateUUID(); // Unique identification of WTaxID
          APResultItem.countryCode = companyCodeList.Code;
          APResultItem.companyCode = companyCodeItem;
          APResultItem.WTaxTypeList = this.getWTaxTypeList(
            companyCodeList.Code
          );
          APResultItem.WTaxCodeList = [
            { Code: "Not Applicable", Text: "Not Applicable" },
          ];
          APResultItem.WTaxType = ["Not Applicable"];
          APResultItem.WTaxCode = ["Not Applicable"];
          APResultItem.twoSeries = false;
          APResultItem.threeSeries = false;
          // Country is IN or US, the WTaxID will has value
          if (companyCodeList.Code === "US" || companyCodeList.Code === "IN") {
            APResultItem.WTaxID =
              !!this.onboardingService.supplierModel.Mapping.wTaxID &&
              this.onboardingService.supplierModel.Mapping.wTaxID.length > 0
                ? this.onboardingService.supplierModel.Mapping.wTaxID[0].WTaxID
                : "";
            countUSIN++;
          } else {
            APResultItem.WTaxID = "";
          }
          if (isFirstMigrationApprove) {
            // APResultItem.sapId = this.onboardingService.supplierModel.Mapping.searchTerm.SAP[0]
            searchTermObj.companyCode.forEach((company: any, i: number) => {
              if (company === companyCodeItem) {
                APResultItem.sapId = searchTermObj.SAP[i];
              }
            });
          }
          this.sapResult.sapResultList.push(APResultItem);
          this.hasCountryUSIN = countUSIN > 0 ? true : false;
        }
      });
    });
  }

  @Input() get visible(): boolean {
    return this._visible;
  }

  set visible(val: boolean) {
    this._visible = val;

    if (this._visible) {
      if (!this.positionInitialized) {
        this.center();
        this.positionInitialized = true;
      }

      this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
      this.bindGlobalListeners();
      this.executePostShowActions = true;
    }

    if (this._visible) {
      this.enableModality();
    } else {
      this.disableModality();
    }
  }

  ngAfterViewInit() {
    this.contentContainer = this.domHandler.findSingle(
      this.el.nativeElement,
      ".ui-dialog-content"
    );

    if (this.appendTo) {
      if (this.appendTo === "body") {
        document.body.appendChild(this.el.nativeElement);
      } else {
        this.domHandler.appendChild(this.el.nativeElement, this.appendTo);
      }
    }
  }

  ngAfterViewChecked() {
    if (this.executePostShowActions) {
      // this.domHandler.findSingle(this.el.nativeElement.children[0], 'button').focus()
      this.executePostShowActions = false;
    }
  }

  center() {
    const container = this.el.nativeElement.children[0];

    let elementWidth = this.domHandler.getOuterWidth(container);
    let elementHeight = this.domHandler.getOuterHeight(container);
    if (elementWidth === 0 && elementHeight === 0) {
      container.style.visibility = "hidden";
      container.style.display = "block";
      elementWidth = this.domHandler.getOuterWidth(container);
      elementHeight = this.domHandler.getOuterHeight(container);
      container.style.display = "none";
      container.style.visibility = "visible";
    }
    const viewport = this.domHandler.getViewport();
    const x = (viewport.width - elementWidth) / 2;
    // Minus 200 px makes the popup window a little higher than current position
    const y = (viewport.height - elementHeight) / 2;

    container.style.left = x + "px";
    container.style.top = y + "px";
  }

  enableModality() {
    if (!this.mask) {
      this.mask = document.createElement("div");
      this.mask.style.zIndex =
        this.el.nativeElement.children[0].style.zIndex - 1;
      this.domHandler.addMultipleClasses(
        this.mask,
        "ui-widget-overlay ui-dialog-mask"
      );
      // Set mask height position to fix the dialog layout issue when scroll down the page
      this.mask.style.height = document.body.clientHeight + "px";
      document.body.appendChild(this.mask);
      this.domHandler.addClass(document.body, "ui-overflow-hidden");
    }
  }

  disableModality() {
    if (this.mask) {
      document.body.removeChild(this.mask);
      this.domHandler.removeClass(document.body, "ui-overflow-hidden");
      this.mask = null;
    }
  }

  close(event: Event) {
    this.hide();
    event.preventDefault();
  }

  hide() {
    this.visible = false;
    this.unbindGlobalListeners();
  }

  moveOnTop() {
    this.el.nativeElement.children[0].style.zIndex = ++DomHandler.zindex;
  }

  bindGlobalListeners() {
    if (this.closeOnEscape && this.closable && !this.documentEscapeListener) {
      this.documentEscapeListener = this.renderer.listen(
        "document",
        "keydown",
        (event) => {
          if (event.which === 27) {
            if (
              this.el.nativeElement.children[0].style.zIndex ===
                DomHandler.zindex &&
              this.visible
            ) {
              this.close(event);
            }
          }
        }
      );
    }

    if (this.responsive) {
      this.zone.runOutsideAngular(() => {
        this.documentResponsiveListener = this.center.bind(this);
        window.addEventListener("resize", this.documentResponsiveListener);
      });
    }
  }

  unbindGlobalListeners() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }

    if (this.documentResponsiveListener) {
      window.removeEventListener("resize", this.documentResponsiveListener);
      this.documentResponsiveListener = null;
    }
  }

  ngOnDestroy() {
    this.disableModality();

    if (this.documentResponsiveListener) {
      this.documentResponsiveListener();
    }

    if (this.documentEscapeListener) {
      this.documentEscapeListener();
    }

    if (this.appendTo && this.appendTo === "body") {
      document.body.removeChild(this.el.nativeElement);
    }

    this.subscription.unsubscribe();
    if(this.ppSubscription){this.ppSubscription.unsubscribe();}
  }

  get isMigrated() {
    return this.onboardingService.supplierModel.Mapping.IsMigrated;
  }

  get isShowRequestorBtn() {
    const isMigrate: boolean =
      this.onboardingService.supplierModel.Mapping.IsMigrated;
    if (isMigrate) {
      return false;
    }
    return (
      this.taskInst.processDefName !==
      ProcessNames.generic_supplierChangeRequest
    );
  }

  get isSAPIDValid() {
    const creationAPIdValid: any = [];
    this.sapResult.sapResultList.forEach((item: SapResultItem) => {
      creationAPIdValid.push(
        this.checkOneSeriesSAPID(item.sapId) && item.sapId.length > 0
      );
    });
    if (
      !!this.sapResult.thirdPartySAPResult &&
      this.sapResult.thirdPartySAPResult.length > 0
    ) {
      this.sapResult.thirdPartySAPResult.forEach(
        (item: ThirdPartySapResultItem) => {
          creationAPIdValid.push(
            this.checkTwoSeriesSAPID(item.sapId) && item.sapId.length > 0
          );
        }
      );
    }
    if (
      !!this.sapResult.gstPartnerResult &&
      this.sapResult.gstPartnerResult.length > 0
    ) {
      this.sapResult.gstPartnerResult.forEach((item: GSPartnerInformation) => {
        if (!!item.gsPartnerList && item.gsPartnerList.length > 0) {
          item.gsPartnerList.forEach((gsItem: GstRegistrationSapItem) => {
            creationAPIdValid.push(
              this.checkThreeSeriesSAPID(gsItem.sapId) &&
                gsItem.sapId.length > 0
            );
          });
        }
      });
    }
    // console.log('============>', !creationAPIdValid.includes(false))
    return !creationAPIdValid.includes(false);
  }

  checkSingleSAPID(sapID: string): boolean {
    return /^\d{9}$/.test(sapID);
  }
  checkOneSeriesSAPID(sapID: string): boolean {
    return /^1\d{8}$/.test(sapID);
  }
  checkTwoSeriesSAPID(sapID: string): boolean {
    return /^2\d{8}$/.test(sapID);
  }
  checkThreeSeriesSAPID(sapID: string): boolean {
    return /^3\d{8}$/.test(sapID);
  }

  validate() {
    let isWTaxValid = true;
    let isTwoThreeValid = true;
    this.sapResult.comments = this.sapResult.comments.trim();
    this.isValidated = true;
    // console.log(!this.sapResult.comments && this.isSAPIDValid);
    if (
      !!this.sapResult.sapResultList &&
      this.sapResult.sapResultList.length > 0
    ) {
      const emptyWTax = this.sapResult.sapResultList.filter(
        (res) =>
          (!!res.WTaxType && res.WTaxType.length === 0) ||
          (!!res.WTaxCode && res.WTaxCode.length === 0)
      );
      isWTaxValid = !!emptyWTax && emptyWTax.length > 0 ? false : true;

      if (this.supplierGeographical === "21") {
        this.sapResult.sapResultList.some((sap) => {
          if (
            !sap.twoSeries &&
            !sap.threeSeries &&
            ((!!this.sapResult.alterNativePayeeResult &&
              this.sapResult.alterNativePayeeResult.length !== 0) ||
              (!!this.sapResult.gstPartnerResult &&
                this.sapResult.gstPartnerResult.length !== 0))
          ) {
            isTwoThreeValid = false;
            return true;
          }
        });
      }
    }
    if (
      this.dialogType === DialogType.sstApprove &&
      (!this.sapResult.comments ||
        !this.isSAPIDValid ||
        !isWTaxValid ||
        !isTwoThreeValid)
    ) {
      return false;
    }
    // sst terminate and qc review validate
    if (
      (this.dialogType === DialogType.qcReject ||
        this.dialogType === DialogType.qcApprove ||
        this.dialogType === DialogType.qcTerminate ||
        this.dialogType === DialogType.sstTerminate ||
        this.dialogType === DialogType.sstReject ||
        this.dialogType === DialogType.terminateSupplier) &&
      !this.sapResult.comments
    ) {
      return false;
    }
    // sst reject sapValue
    if (this.dialogType === DialogType.sstReject && !this.sapResult.value) {
      return false;
    }
    return true;
  }

  get ShowActionTaken() {
    return (
      this.sapResult.actionTaken &&
      this.dialogType.toString() === AP_ReviewStatus.SST_approve
    );
  }

  async submit() {
    if (!this.validate()) {
      return false;
    }
    let result: any;
    const WTaxIDList: Array<WTaxIDModel> = [];
    // update task variables
    if (this.dialogType === DialogType.sstReject) {
      if (this.sapResult.value === RejectComments.requestor) {
        this.taskInst.variables = {
          SST_RejectTo: "buyer",
        };
      } else if (this.sapResult.value === RejectComments.supplier) {
        this.taskInst.variables = {
          SST_RejectTo: "supplier",
        };
      }
    }
    const saveUrl: any = this.getSaveURL(this.dialogType);
    this.loadingService.openLoading(saveUrl);
    // update supplier mapping
    this.updateMapping();
    if (
      !!this.sapResult.thirdPartySAPResult &&
      this.sapResult.thirdPartySAPResult.length > 0
    ) {
      const tempThirdPartySapIdList: any = [];
      this.sapResult.thirdPartySAPResult.forEach(
        (item: ThirdPartySapResultItem) => {
          tempThirdPartySapIdList.push(item.sapId);
        }
      );
      this.onboardingService.supplierModel.Mapping.searchTerm.thirdPartySAP =
        tempThirdPartySapIdList;
      this.onboardingService.supplierModel.Mapping.gstSapItem =
        this.gstRegistrationSapList;
    }
    let mapping: SupplierMappingDTO = this.onboardingService.supplierModel.Mapping
    // exec task
    if (this.dialogType === DialogType.sstReject) {
      if (this.sapResult.value === RejectComments.requestor) {       
        if (this.isNewVersion) {
          result = await this.buyerService.apRejectToBuyerEnhanced(mapping);
        } else {
          result = await this.buyerService.apRejectToBuyer(this.onboardingService.supplierModel);
        }
      } else if (this.sapResult.value === RejectComments.supplier) {
        if (this.isNewVersion) {
          result = await this.buyerService.apRejectToSupplierEnhanced(mapping);
        } else {
          result = await this.buyerService.apRejectToSupplier(this.onboardingService.supplierModel);
        }       
      }
    } else if (this.dialogType === DialogType.sstApprove) {
      const tempSapIdList: any = [];
      this.sapResult.sapResultList.forEach((item: SapResultItem) => {
        tempSapIdList.push(item.sapId);
        WTaxIDList.push(this.generateWTaxIDModel(item.WTaxUUID, item.WTaxID));
      });
      // const tempThirdPartySapIdList: any = []
      // this.sapResult.thirdPartySAPResult.forEach((item: ThirdPartySapResultItem) => {
      //   tempThirdPartySapIdList.push(item.sapId)
      // })
      this.onboardingService.supplierModel.Mapping.searchTerm.SAP =
        tempSapIdList;
      this.onboardingService.supplierModel.Mapping.gstSapItem =
        this.gstRegistrationSapList;
      this.onboardingService.supplierModel.Mapping.wTaxID = WTaxIDList;
      const supplierModelCopy = cloneDeep(this.onboardingService.supplierModel);
      supplierModelCopy.Mapping.assessmentRate.sapResult.map((res) => {
        if (res.sapResultList) {
          res.sapResultList.map((resl) => {
            if (resl.WTaxTypeList) {
              delete resl.WTaxTypeList;
            }
            if (resl.WTaxCodeList) {
              delete resl.WTaxCodeList;
            }
          });
        }
      });
      if (this.isNewVersion) {
        result = await this.buyerService.sstApproveEnhanced(mapping);
      } else {
        result = await this.buyerService.sstApprove(supplierModelCopy);
      }
    } else if (this.dialogType === DialogType.sstTerminate) {
      result = await this.buyerService.sstTerminate(
        this.onboardingService.supplierModel
      );
    } else if (this.dialogType === DialogType.qcApprove) {      
      if (this.isNewVersion) {
        result = await this.buyerService.qcApproveEnhanced(mapping);
      } else {
        result = await this.buyerService.qcApprove(this.onboardingService.supplierModel);
      }
    } else if (this.dialogType === DialogType.qcReject) {
      if (this.isNewVersion) {
        result = await this.buyerService.qcRejectEnhanced(mapping);
      } else {
        result = await this.buyerService.qcReject(this.onboardingService.supplierModel);
      }
    } else if (this.dialogType === DialogType.qcTerminate) {
      result = await this.buyerService.qcTerminate(
        this.onboardingService.supplierModel
      );
    } else if (this.dialogType === DialogType.terminateSupplier) {
      result = await this.buyerService.terminateSupplier(
        this.onboardingService.supplierModel
      );
    }
    if (result.isSuccess) {
      this.hide();
      this.confirmation = null;
      this.loadingService.closeLoading();
      this.isEmptyChoice = false;
      await this.dialogService.dialog(
        "You have submitted successfully.",
        ButtonCommands.Close,
        DialogType.success,
        OnBoardMessage.APResultSendSuccess
      );
      const TsmId: string = this.onboardingService.supplierModel.Mapping.TsmId;
      const apFlag: boolean =
        this.auth.passport.buyer.RoleCode === "AP_SST" ||
        this.auth.passport.buyer.RoleCode === "AP_QC" ||
        this.auth.passport.buyer.RoleCode === "buyerAdmin" || 
        this.auth.passport.buyer.RoleCode === "systemAdmin";
      if (
        !!TsmId &&
        this.dialogType === DialogType.terminateSupplier &&
        !apFlag
      ) {
        // let landing_url = environment.landing_url
        // window.location.href = landing_url
        const env = sessionStorage.getItem("tsmEnv");
        const url =
          env === "dev"
            ? environment.landingdev_url
            : env === "perf"
            ? environment.landingperf_url
            : env === "hotfix"
            ? environment.landinghotfix_url
            : env === "stage"
            ? environment.landingstage_url
            : env === "test"
            ? environment.landingtest_url
            : environment.landing_url;
        window.location.href =
          url + "?login_hint=" + this.auth.passport.buyer.Email;
      } else {
        this.router.navigate(["buyer"]);
      }
    }
  }

  generateWTaxIDModel(WTaxUUID: string, WTaxID: string) {
    const WTaxIDModel: WTaxIDModel = {
      WTaxUUID: WTaxUUID,
      WTaxID: WTaxID,
    };
    return WTaxIDModel;
  }

  getSaveURL(dialogType: DialogType) {
    let saveUrl: any;
    switch (dialogType) {
      case DialogType.sstReject:
        if (this.sapResult.value === RejectComments.requestor) {
          saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_SST_RejectToBuyer}`;
        }
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_SST_RejectToSupplier}`;
        break;
      case DialogType.sstTerminate:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_SST_Terminate}`;
        break;
      case DialogType.sstApprove:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_SST_Approve}`;
        break;
      case DialogType.qcApprove:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_QC_Approve}`;
        break;
      case DialogType.qcReject:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_QC_Reject}`;
        break;
      case DialogType.qcTerminate:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.AP_QC_Terminate}`;
        break;
      case DialogType.terminateSupplier:
        saveUrl = `${environment.gateway}${LoadingUrlMapping.terminateSupplier}`;
        break;
    }
    return saveUrl;
  }

  updateMapping() {
    this.sapResult.type = this.dialogType.toString();
    this.sapResult.createdOn = " on " + moment.utc().toString();      
    this.sapResult.createdBy = "By " + this.auth.passport.profile.name;
    this.sapResult.gstSAPList = this.gstRegistrationSapList;
    this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult.push(
      this.sapResult
    );
    this.onboardingService.supplierModel.Mapping.executeTask = this.taskInst;
  }

  cancel() {
    if (this.dialogType === DialogType.sstApprove) {
      this.hide();
      // this.apApproveList = []
      this.sapResult = createAPReviewModel();
      const apResultLength =
        this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult
          .length;
      let sapResultList = [];
      this.thirdPartySAPList = [];
      this.alternativePayList = [];
      this.gstRegistrationSapList = [];
      if (apResultLength > 0) {
        sapResultList =
          cloneDeep(this.onboardingService.supplierModel.Mapping.assessmentRate.sapResult[
            apResultLength - 1
          ].sapResultList);
      }
      const companyCodes =
        this.onboardingService.supplierModel.Mapping.searchTerm.companyCode;
      // init sapResult
      this.initSapResult(sapResultList, companyCodes);
      this.confirmation = null;
    } else {
      this.hide();
      // this.apApproveList = []
      this.confirmation = null;
      this.selectedReason = null;
      this.selectedReasonDetails = '';
      this.selectReasons = null;
      this.rejectionOption = null;
      this.showReasons = false;
      this.rejectionOptions = null;
      this.resetValidationState();
    }
  }

  initSapResult(sapResultList: Array<SapResultItem>, companyCodes: string[]) {
    if (sapResultList) {
      sapResultList.forEach((sl) => {
        sl.WTaxCode = sl.WTaxCode ? sl.WTaxCode : ["Not Applicable"];
        sl.WTaxType = sl.WTaxType ? sl.WTaxType : ["Not Applicable"];
        sl.WTaxCodeList = !sl.WTaxCodeList
          ? this.getWTaxCodeList(
            sl.countryCode, !_.isEqual(sl.WTaxType, ["Not Applicable"]) ? sl.WTaxType : null
            )
          : sl.WTaxCodeList;
        sl.WTaxTypeList = !sl.WTaxTypeList
          ? this.getWTaxTypeList(sl.countryCode)
          : sl.WTaxTypeList;
        if (
            (sl.countryCode === 'US' || sl.countryCode === 'IN') 
            && this.onboardingService.supplierModel.Mapping.IsMigrated
            && !sl.WTaxID
          ) {
            sl.WTaxID = !!this.onboardingService.supplierModel.Mapping.wTaxID &&
            this.onboardingService.supplierModel.Mapping.wTaxID.length > 0
              ? this.onboardingService.supplierModel.Mapping.wTaxID[0].WTaxID
              : '';
        }
      });
    }
    this.sapResult.sapResultList = sapResultList;
    // check for new company code
    const sapResultListStr = JSON.stringify(this.sapResult.sapResultList);
    const isFirstMigrationApprove =
      this.taskInst.processDefName ===
        ProcessNames.generic_supplierMigrateSetup &&
      this.taskInst.name === AllTaskKey.SST_review
        ? true
        : false;
    companyCodes.forEach((companyCodeItem) => {
      if (!sapResultListStr.includes(companyCodeItem)) {
        this.addAPResultItem(companyCodeItem, isFirstMigrationApprove);
      }
    });
    // check if there is a company code removed
    this.sapResult.sapResultList = this.sapResult.sapResultList.filter(
      (sapResultItem: SapResultItem) => {
        if (companyCodes.includes(sapResultItem.companyCode)) {
          return sapResultItem;
        }
      }
    );
  }

  saveSectionEvent(event, type, sapResultItem) {
    this.isEmptyChoice = false;
    if (type === "WTaxType") {
      sapResultItem.WTaxType = event;
      sapResultItem.WTaxCodeList = this.getWTaxCodeList(
        sapResultItem.countryCode,
        event
      );
      sapResultItem.WTaxCode = [];
    } else if (type === "WTaxCode") {
      sapResultItem.WTaxCode = event;
    }
  }

  getWTaxTypeList(countryCode: string) {
    const result: Array<any> = [
      { Code: "Not Applicable", Text: "Not Applicable" },
    ];
    const WTaxTypeList = this.withholdingTax.filter(
      (item) => item.Code === countryCode
    );

    if (WTaxTypeList.length !== 0) {
      const copyWTaxTypeList = cloneDeep(WTaxTypeList[0]);
      copyWTaxTypeList.Items.map((des) => {
        des.Items.map((type) => {
          delete type.Items;
          result.push(type);
        });
      });
    }

    return result;
  }

  getWTaxCodeList(countryCode: string, WTaxType) {
    let result: Array<any> = [
      { Code: "Not Applicable", Text: "Not Applicable" },
    ];
    if (!!WTaxType && WTaxType.length > 0) {
      const countryWithholdingTax = this.dropDownDict.filter(
        (dict) => dict.Code === "CountryWithholdingTax"
      )[0].Items;
      const WTaxTypeList = countryWithholdingTax.filter(
        (item) => item.Code === countryCode
      );

      if (WTaxTypeList.length !== 0) {
        const copyWTaxTypeList = cloneDeep(WTaxTypeList[0]);
        copyWTaxTypeList.Items.map((des) => {
          const filterType = des.Items.filter((d) => WTaxType.includes(d.Code));
          filterType.map((type) => {
            result = result.concat(type.Items);
          });
        });
      }
    }

    return result;
  }

  setWTaxValue(newSapResultList: Array<any>, sapResult: Array<any>) {
    const hasWTaxIndex = sapResult.lastIndexOf(
      (item) =>
        item.type === "AP QC Review Approved" ||
        item.type === "AP SST Review Approved"
    );
    const hasWTaxSapResult = [];
    let countUSIN = 0;
    sapResult.forEach((res) => {
      if (
        res.type === "AP QC Review Approved" ||
        res.type === "AP SST Review Approved"
      ) {
        res.sapResultList.map((sap) => {
          const copySap = cloneDeep(sap);
          hasWTaxSapResult.push(copySap);
        });
      }
    });
    newSapResultList.map((res) => {
      hasWTaxSapResult.map((tax) => {
        if (res.companyCode === tax.companyCode) {
          if (!!tax.WTaxUUID && tax.WTaxUUID !== "supplier_submit") {
            res.WTaxUUID = tax.WTaxUUID;
          } else {
            res.WTaxUUID = this.generateUUID();
          }
          res.WTaxType = tax.WTaxType;
          res.WTaxCode = tax.WTaxCode;

          if (
            !!this.onboardingService.supplierModel.Mapping.wTaxID &&
            this.onboardingService.supplierModel.Mapping.wTaxID.length > 0
          ) {
            this.onboardingService.supplierModel.Mapping.wTaxID.map(
              (WTaxIDs) => {
                if (
                  WTaxIDs.WTaxUUID === tax.WTaxUUID ||
                  WTaxIDs.WTaxUUID === "supplier_submit"
                ) {
                  res.WTaxID = WTaxIDs.WTaxID;
                }
              }
            );
          }

          if (!!this.thirdPartyList && this.thirdPartyList.length > 0) {
            res.twoSeries = tax.twoSeries;
          } else {
            res.twoSeries = false;
          }

          if (this.gstRegistrationList && this.gstRegistrationList.length > 0) {
            res.threeSeries = tax.threeSeries;
          } else {
            res.threeSeries = false;
          }
        }
      });
      res.WTaxType =
        !!res.WTaxType && res.WTaxType.length === 0
          ? ["Not Applicable"]
          : res.WTaxType;
      res.WTaxCode =
        !!res.WTaxCode && res.WTaxCode.length === 0
          ? ["Not Applicable"]
          : res.WTaxCode;
      res.WTaxTypeList = this.getWTaxTypeList(res.countryCode);
      res.WTaxCodeList = this.getWTaxCodeList(
        res.countryCode,
        // @ts-ignore
        res.WTaxType !== ["Not Applicable"] ? res.WTaxType : null
      );

      if (res.countryCode === "US" || res.countryCode === "IN") {
        countUSIN++;
      }
    });
    if (countUSIN > 0) {
      this.hasCountryUSIN = true;
    }
  }

  checkedSapSeries(value, sapResultItem, seriesType) {
    if (seriesType === "twoSeries") {
      if (sapResultItem.twoSeries) {
        const payee: Array<any> = this.alternativePayList.filter(
          (pay) => pay.alternativePayName === ""
        );
        if (payee.length > 0) {
          payee[0].alternativePayName = sapResultItem.companyCode;
        } else if (this.alternativePayList.length > 0) {
          const thirdPartyList = [];
          this.alternativePayList[0].thirdPartyList.map((thirdParty) => {
            const copyThirdParty = cloneDeep(thirdParty);
            thirdPartyList.push(copyThirdParty);
          });
          this.alternativePayList.push({
            alternativePayName: sapResultItem.companyCode,
            thirdPartyList: thirdPartyList,
          });
          this.sapResult.alterNativePayeeResult = this.alternativePayList;
        }
      } else {
        const existAlt: AlternativePayInformation =
          this.alternativePayList.find(
            (alt) => alt.alternativePayName === sapResultItem.companyCode
          );
        if (this.alternativePayList.length === 1) {
          existAlt.alternativePayName = "";
        } else {
          const index = this.alternativePayList.indexOf(existAlt);
          if (index > -1) {
            this.alternativePayList.splice(index, 1);
          }
        }
        this.sapResult.alterNativePayeeResult = this.alternativePayList;
      }
    } else if (seriesType === "threeSeries") {
      if (sapResultItem.threeSeries) {
        const gs: Array<any> = this.gsPartnerList.filter(
          (gs) => gs.gsPartnerName === ""
        );
        if (gs.length > 0) {
          gs[0].gsPartnerName = sapResultItem.companyCode;
        } else if (this.gsPartnerList.length > 0) {
          const gsPartnerList = [];
          this.gsPartnerList[0].gsPartnerList.map((gsPartner) => {
            const copyGSPartner = cloneDeep(gsPartner);
            gsPartnerList.push(copyGSPartner);
          });
          this.gsPartnerList.push({
            gsPartnerName: sapResultItem.companyCode,
            gsPartnerList: gsPartnerList,
          });
          this.sapResult.gstPartnerResult = this.gsPartnerList;
        }
      } else {
        const existGS: GSPartnerInformation = this.gsPartnerList.find(
          (gs) => gs.gsPartnerName === sapResultItem.companyCode
        );
        if (this.gsPartnerList.length === 1) {
          existGS.gsPartnerName = "";
        } else {
          const index = this.gsPartnerList.indexOf(existGS);
          if (index > -1) {
            this.gsPartnerList.splice(index, 1);
          }
        }
        this.sapResult.gstPartnerResult = this.gsPartnerList;
      }
    }
  }

  ppSubmit() {
    if(this.selectedReason && this.selectedReasonDetails){
      this.isPPApproveSubmitPopupVisible = true;
    }
  }
  ppApproveSubmitClose(){
    this.isPPApproveSubmitPopupVisible = false;
  }
  async ppApproveSubmit(){
    let mapping: SupplierMappingDTO = this.onboardingService.supplierModel.Mapping;
    let additionalInterventionIds: any;
    let additionalInterventionIdArray: string[] = [];
    try {
      additionalInterventionIds = await this.buyerService.getAdditionalInterventionIds(mapping.supplierCode);
      additionalInterventionIds.data.forEach(id => {
        additionalInterventionIdArray.push(id.additionInterventionEmailId)
      });
    } catch (error) {
      console.error('Error fetching additional intervention IDs:', error);
      return;
    }
    const loggedInUser = this.auth.passport.profile.email;
    const approvePayload = {suppliercode:mapping.supplierCode, reasonid:this.selectedReason?.ID, comments:this.selectedReasonDetails, createdby:loggedInUser, additionalInterventionIds: additionalInterventionIdArray?additionalInterventionIdArray:[]};
    let ppReviewresult: any;
    try {
      ppReviewresult = await this.buyerService.ppReview(approvePayload, 'pplusapprove');
    } catch (error) {
      console.error('Error during PP review approve submission:', error);
      return;
    }
    this.commonService.checkAccess(loggedInUser, mapping.supplierCode);
    this.isPPApproveSubmitPopupVisible = false;
    this.visible = false;
    this.selectedReason = null;
    this.selectedReasonDetails = '';
    this.router.navigate(["buyer"]);
  }

  showReasons: boolean = false; // Variable to toggle visibility
  handleRejectionChange(){
    this.selectedReason = null; // Reset selected reason
    this.showReasons = this.rejectionOption.reason === 'Reject and Terminate';
  }
  validationState = {
    rejectionOption: false,
    selectedReason: false,
    selectedReasonDetails: false,
  };
  async ppRejectSubmit() {
    // Reset validation state
    this.resetValidationState();
 
    // Perform validation
    this.validationState.rejectionOption = !this.rejectionOption;
    this.validationState.selectedReason = this.showReasons && !this.selectedReason;
    this.validationState.selectedReasonDetails = !this.selectedReasonDetails.trim();;
 
    // If any validation fails, do not proceed
    if (Object.values(this.validationState).some((isInvalid) => isInvalid)) {
      return;
    }
 
    // Submit logic
    let mapping: SupplierMappingDTO = this.onboardingService.supplierModel.Mapping;
    const loggedInUser = this.auth.passport.profile.email;
    let result: any;
    const rejectPayload = {suppliercode:mapping.supplierCode, reasonid:this.rejectionOption?.ID, comments:this.selectedReasonDetails, createdby:loggedInUser, ...(this.showReasons ? { selectedreasonid: this.selectedReason?.ID } : {})};
    result = await this.buyerService.ppReview(rejectPayload,'pplusreject');
    this.commonService.checkAccess(loggedInUser, mapping.supplierCode);
   
    this.visible = false;
    this.rejectionOption = null;
    this.selectedReason = null;
    this.selectedReasonDetails = "";
    this.rejectionOptions = null;
    this.showReasons = false;
 
    // Reset form
    this.resetValidationState();
    this.router.navigate(["buyer"]);
  }
 
  resetValidationState() {
    this.validationState = {
      rejectionOption: false,
      selectedReason: false,
      selectedReasonDetails: false,
    };
  }
  //Comment the send to sap part code as the asp function delay deployment
  // async sendDataToSap(){
  //   let sapInfo = {
  //     supplierCode:this.onboardingService.supplierModel.Mapping.supplierCode,
  //     sapResults: [],
  //     email: this.auth.passport.buyer.Email
  //   }

  //   let tempContryList = []
  //   for (let index = 0; index < this.sapResult.sapResultList.length; index++) {
  //     const sap = this.sapResult.sapResultList[index];
  //     if(!tempContryList.includes(sap.countryCode)){
  //       tempContryList.push(sap.countryCode)
  //       const sapResult = {
  //         Country:sap.countryCode,
  //         sapItems:[]
  //       }
  //       const sapItem = {
  //         sapId:sap.sapId,
  //         companyCode:sap.companyCode
  //       }
  //       sapResult.sapItems.push(sapItem)
  //       sapInfo.sapResults.push(sapResult)
  //     }
  //     else{
  //       const sapItem = {
  //         sapId:sap.sapId,
  //         companyCode:sap.companyCode
  //       }
  //       sapInfo.sapResults.find(s => s.Country == sap.countryCode).sapItems.push(sapItem)
  //     }
  //   }
  //   console.info(sapInfo)
  //   const res = await this.buyerService.sendDataToSap(sapInfo)
  //   console.info(res)
  // }
}
