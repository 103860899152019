import { Injectable } from '@angular/core';
import { SapResultItem, SapResultModel } from 'src/app/interfaces/mapping';
import {SapIdStatus,AP_ReviewStatus} from '../../../../interfaces/mapping'
@Injectable({
  providedIn: 'root'
})
export class SupplierDetailService {

  constructor() {}

  filterSapIdStatus(sapResultList: SapResultModel[], isMigrated: boolean): { activeSapResultList: SapResultItem[], blockedSapResultList: SapResultItem[] } {
    const activeSapResultList: SapResultItem[] = [];
    const blockedSapResultList: SapResultItem[] = [];

    if (sapResultList.length > 0) {

      if(!isMigrated){
        const filteredsapResult = sapResultList.find(s=>s.type == AP_ReviewStatus.QC_approve)
        filteredsapResult?.sapResultList.forEach((res) => {
        if(res.sapIDStatus === SapIdStatus.Active) {
          console.log('Active', res.sapIDStatus);
          activeSapResultList.push(res);
        }else if(res.sapIDStatus === SapIdStatus.Blocked) {
          console.log('Blocked', res.sapIDStatus);
          blockedSapResultList.push(res);
        }
      });
    }
    else {
      const filteredsapResult = sapResultList[0].sapResultList;
      filteredsapResult.forEach((res) => {
        if(res.sapIDStatus === SapIdStatus.Active) {
          console.log('Active', res.sapIDStatus);
          activeSapResultList.push(res);
        }else if(res.sapIDStatus === SapIdStatus.Blocked) {
          console.log('Blocked', res.sapIDStatus);
          blockedSapResultList.push(res);
        }
      });
    }
    }
    return { activeSapResultList, blockedSapResultList };
  }
}
